import React, { createContext, useContext, useState } from "react";
import {
  deleteAllLocalData,
  getUserDetails,
  saveUserDetails
} from "../helper/local-storage/local-storage-helper";

import {deleteAllSessionData} from "../helper/session-storage/session-storage-helper"

// Create the context
export const AuthContext = createContext();

// Create a context provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getUserDetails() || null);

  const login = async (userData) => {
    setUser(userData);
    saveUserDetails(userData);
  };

  const logout = () => {
    setUser(null);
    deleteAllLocalData();
    deleteAllSessionData();
  };

  const isUserLoggedIn = () => getUserDetails() || null;

  const contextValues = {
    user,
    login,
    logout,
    isUserLoggedIn,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook for using the context
export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
