
 // Function to store multiple video IDs in sessionStorage
 export const storeVideoIds = (videoId) => {
    // Retrieve the existing video IDs from sessionStorage
    const existingIds = JSON.parse(sessionStorage.getItem('videoIds')) || [];
  
    // Add the new video ID to the existing list if it's not already there
    if (!existingIds.includes(videoId)) {
      existingIds.push(videoId);
      sessionStorage.setItem('videoIds', JSON.stringify(existingIds));
    }
  };
  
  // Function to check if a specific video ID is present in sessionStorage
  export const checkVideoId = (videoId) => {
    // Retrieve the list of video IDs from sessionStorage
    const videoIds = JSON.parse(sessionStorage.getItem('videoIds')) || [];
  
    return videoIds.includes(videoId);
  };

  
export const deleteAllSessionData = () => {
    try {
      sessionStorage.clear();
    } catch (error) {
      throw error;
    }
  };