import {
  AUTH_TOKEN_KEY,
  I18_NEXT_LANGUAGE_KEY,
  USER_DETAILS_KEY,
} from "../../global/constants/app-constants";

export const saveAuthToken = (data) => {
  try {
    let jsonOfItem = localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(data));
    return jsonOfItem;
  } catch (error) {
    throw error;
  }
};

export const getAuthToken = () => {
  try {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) return JSON.parse(token);
    return undefined;
  } catch (error) {
    throw error;
  }
};

export const deleteAuthToken = () => {
  try {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  } catch (error) {
    throw error;
  }
};

export const saveUserDetails = (data) => {
  try {
    let jsonOfItem = localStorage.setItem(
      USER_DETAILS_KEY,
      JSON.stringify(data)
    );
    return jsonOfItem;
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = () => {
  try {
    const user = localStorage.getItem(USER_DETAILS_KEY);
    if (user) return JSON.parse(user);
    return null;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const deleteUserDetails = () => {
  try {
    localStorage.removeItem(USER_DETAILS_KEY);
  } catch (error) {
    throw error;
  }
};

export const deleteAllLocalData = () => {
  try {
    localStorage.clear();
    // localStorage.removeItem(AUTH_TOKEN_KEY);
    // localStorage.removeItem(USER_DETAILS_KEY);
  } catch (error) {
    throw error;
  }
};

export const setUserVideoSegment = (key, data) => {
  try {
    let jsonOfItem = localStorage.setItem(key, JSON.stringify(data));
    return jsonOfItem;
  } catch (error) {
    throw error;
  }
};

export const getUserVideoSegment = (key) => {
  try {
    const segments_array = localStorage.getItem(key);
    if (segments_array) return JSON.parse(segments_array);
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserPreferredLanguage = () => {
  try {
    const lng = localStorage.getItem(I18_NEXT_LANGUAGE_KEY);
    if (lng) return lng;

    return null
  } catch (error) {
    console.error(error);
    return null;
  }
};

